@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
@import "../../assets/styles/_variables";

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
}

#root {
	position: relative;
	background-color: #fefeff;
	overflow: hidden;
}

.header__logo--slogan {
	font-family: "Tahoma";
	p {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 18px;
		color: $secondary-color;
	}

	span {
		color: $primary-color;
		font-size: 16px;
		line-height: 16px;
		overflow: unset;
	}
}

.btn--download {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;
	color: $primary-color;
	padding: 16px 20px;
	background-color: $secondary-color;
	border-radius: $border-radius;
	border: none;
	cursor: pointer;
}

.btn--download:hover {
	background-color: $primary-color;
	color: $white-color;
}

.digital__title {
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 52px;
	color: $secondary-color;
	margin-bottom: 40px;
}

.digital__description {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.feature__title {
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 52px;
	text-align: center;
	margin-bottom: 80px;
}

.feature__template .template {
	box-shadow: none;
}

.wrap-res {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media only screen and (max-width: 1900px) {
	.wrap-res {
		width: 90%;
		display: flex;
		align-items: center;
		margin: 0 auto;
		justify-content: space-between;
	}
}
@media only screen and (min-width: 320px) and (max-width: 598px) {
	.wrap-res {
		width: 100%;
	}
	.header__logo--slogan {
		p {
			font-weight: 700;
			font-size: 14px;
			line-height: 21px;
		}

		span {
			font-size: 12px;
		}
	}
	.digital__title {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		margin-bottom: 8px;
	}

	.digital__description {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
	}

	.swiper-horizontal {
		width: 100%;
	}

	.feature__title {
		margin-bottom: 20px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}

	.btn--download {
		padding: 8px 12px;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
	}
}
::-webkit-scrollbar {
	height: 3px !important;
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
}
.swiper-wrapper {
	transition-duration: 0.2s !important;
	// -webkit-transition: all 0.5s ease-in-out !important;
	// transition: all 0.5s ease !important;
	animation: 0.1s linear forwards !important;
}

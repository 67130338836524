@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}

.container {
  width: 100vw;
  height: 100vh;
  background: rgb(148 156 183 / 72%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.confetti-container {
  /* perspective: 700px; */
  perspective: 100vw;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}

.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}

.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}

.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

@import "../../../assets/styles/_variables";
.root {
  margin: 160px 110px 0;
  color: $primary-color;
  &__link {
    display: flex;
    margin-bottom: 75px;
    &--title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      font-family: "Inter";

      span {
        overflow: hidden;
        margin-right: 5px;
      }
      span:last-child {
        margin-right: 0;
      }
    }
    &--line {
      margin-left: 32px;
    }
    &--button {
      display: flex;

      div {
        margin-left: 32px;
      }
    }

    button {
      color: $primary-color;
      padding: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-family: "Inter";
    }
    &--active {
      color: $secondary-color !important;
    }
  }
}
@media only screen and (max-width: 599px) {
  .root {
    margin: 68px 0 20px 0;

    &__link {
      flex-direction: column;
      align-items: unset;
      margin-bottom: 20px;
      margin-left: 16px;
      &--title {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 21px;
      }
      &--line {
        display: none;
      }
      &--button {
        div {
          margin-right: 16px !important;
          margin-left: 0;
        }
      }
      button {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.library {
    margin-top: 80px;
    padding-top: 11px;
    position: relative;
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .library {
        margin-top: 44px;
        padding-top: 0;
    }
}

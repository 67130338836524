@use '../scss/' as *;

.features {
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;

    h4 {
        font-size: 32px;
        line-height: 34px;
        font-weight: $weight-bold;
        margin-bottom: 40px;

        @include respond(phone) {
            font-size: 18px;
            font-weight: $weight-normal;
            line-height: 19px;
            margin-bottom: 16px;  
        }
    }

    .cards {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;

        @include respond(phone) {
            grid-template-columns: 1fr 1fr;
            padding: 0 24px;
            gap: 15px;
        }

        .card {          
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 32px;
            gap: 24px;
            border-radius: 20px;
            @include respond(phone) {
                gap: 13px;
                padding: 13px 7.5px;
                border-radius: 8px;
            }

            .number {
                width: 72px;
                height: 72px;
                border-radius: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include respond(phone) {
                    width: 32px;
                    height: 32px;

                    img {
                        width: 7px;
                        height: 13px;
                    }
                }
            }

            p {
                font-size: 30px;
                line-height: 32px;
                font-weight: 400;

                @include respond(phone) {
                    font-size: 14px;
                    line-height: 15px;
                }
            }
        }

        .cardRed {
            background-color: $red-light;

            .number {
                background-color: $red;
            }
        }

        .cardYellow {
            background-color: $yellow-light;

            .number {
                background-color: $yellow;
            }
        }

        .cardSprint {
            background-color: $sprint-green-light;

            .number {
                background-color: $sprint-green;
            }
        }

        .cardBlue {
            background-color: $blue-light;

            .number {
                background-color: $blue;
            }
        }

        .cardGreen {
            background-color: $green-light;

            .number {
                background-color: $green;
            }
        }

        .cardPurple {
            background-color: $purple-light;

            .number {
                background-color: $purple;
            }
        }
    }
}
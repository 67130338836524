.navbar {
  width: 100%;
  padding: 37px 0px 107px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.calendar>a {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  text-transform: capitalize;
  text-decoration: none;
  color: #333333;
}

.paths {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
}

.paths>a {
  margin-right: 80px;
  text-decoration: none;
  color: #333333 !important;
}

.paths>a:first-child {
  font-weight: bold;
}

.paths>div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4abb77;
  border-radius: 12px;
  font-weight: 500;
  padding: 13px 28px;
  width: 200px;
  height: 56px;
  cursor: pointer;
}

.paths>div>a {
  text-decoration: none;
  color: #333333 !important;
  position: absolute;
  z-index: 9;
}

.paths-mobile {
  height: 56px;
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.menu-mobile {
  width: 100%;
  position: absolute;
  top: 83px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #fdefe670;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.menu-mobile>a {
  text-decoration: none;
  color: #333333;
  margin-top: 20px;
}

.menu-mobile>a:first-child {
  font-weight: bold;
}

.menu-mobile>div>a {
  font-weight: 600;
  margin: 20px 0px;
  color: #4abb77;
}

.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
  margin-bottom: 110px;
}

.banner img {
  width: 140px;
  height: 140px;
  margin-bottom: 36px;
}

.title {
  display: flex;
  font-size: 140px;
  line-height: 140px;
}

.title>div:first-child {
  font-weight: bold;
  margin-right: 15px;
}

.title>div:last-child {
  font-style: italic;
  font-family: Agrandir-Tight;
}

.subtitle {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 56px;
  text-align: center;
  max-width: 900px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4abb77;
  border-radius: 12px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  width: 310px;
  height: 80px;
  cursor: pointer;
  padding-top: 4px;
}

.btn>a {
  text-decoration: none;
  color: #333333 !important;
}

.cov-button {
  position: relative;
  font-family: Agrandir-Medium;
}

.btn-blur {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4abb77;
  border-radius: 12px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  width: 310px;
  height: 80px;
  cursor: pointer;
  padding-top: 4px;
  font-family: Agrandir-Medium;
}


.btn-blur>a {
  text-decoration: none;
  color: #333333 !important;
  position: absolute;
  z-index: 9;
}

.blur-dot-sm {
  position: absolute;
  height: 100px;
  width: 100px;
  background-color: #4aba78;
  filter: blur(100px);
}

.blur-dot-lg {
  position: absolute;
  height: 174px;
  width: 174px;
  background-color: #4aba78;
  filter: blur(160px);
}

.social-media {
  margin-top: 35px;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 24px;
  gap: 10px;
}

.social-img-wrapper {
  display: flex;
  gap: 10px;
}

.social-media img {
  width: 40px;
  height: 40px;
  margin-bottom: 0;
}

@media not all and (min-resolution:.001dpcm) {
    .blur-dot-lg {
      background: none;
    }
  }

@media (max-width: 1200px) {
  .title {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (max-width: 940px) {
  .title {
    font-size: 60px;
    line-height: 60px;
  }

  .subtitle {
    font-size: 30px;
    line-height: 40px;
    max-width: 700px;
  }

  .paths-mobile {
    display: flex;
  }

  .paths {
    display: none;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 40px;
    line-height: 40px;
  }

  .title div:first-child {
    margin-right: 6px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 24px;
    max-width: 300px;
  }

  .btn {
    font-size: 32px;
    line-height: 40px;
    width: 280px;
    height: 70px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding-bottom: 50px;
  }

  .banner img {
    max-width: 250px;
    margin-bottom: 30px;
  }

  .title div:first-child {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 30px;
    font-size: 16px;
    max-width: 380px;
  }
}

@media (max-width: 450px) {
  .title div {
    font-size: 32px;
    line-height: 32px;
    ;
  }

  .subtitle {
    font-size: 12px;
    max-width: 280px;
  }

  .social-media {
    flex-direction: column;
  }

  .social-img-wrapper {
    display: flex;
    gap: 10px;
  }

  .social-img-wrapper img {
    margin-bottom: 0;
  }

  .download-btn {
    margin-top: 20px;
  }
}
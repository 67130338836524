@import "../../assets/styles/variables";

.customer__review {
  background-color: $primary-section-bg;
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/svg/customer-bg.svg");

  &--title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 60px;
  }

  &--list {
    padding: 0 162px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .review__item {
      width: 440px;
      height: 306px;
      padding: 32px;
      border-radius: $border-radius;
      background-color: $white-color;
      box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);

      &--customer {
        display: flex;
        gap: 32px;
        margin-bottom: 27px;

        .customer__avt {
          img {
            padding: 3px;
            border-radius: 50%;
            border: 2px solid $secondary-color;
            width: 64px;
            height: 64px;
            @media only screen and (min-width: 320px) and (max-width: 598px) {
              width: 31px;
              height: 31px;
            }
          }
        }

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: $secondary-color;
        }
      }

      &--comment {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &--group {
    position: absolute;
    width: 522.25px;
    height: 167.37px;
    top: 0;
    right: -120px;
  }

  &--sharpener {
    position: absolute;
    left: 10.17%;
    right: 87.41%;
    top: 10px;
    bottom: 91.53%;
  }

  &--circle {
    position: absolute;
    left: 13.8%;
    top: 20.82%;
  }

  &--paper {
    position: absolute;
    left: 3.8%;
    top: 12.14%;
    transform: rotate(86.84deg);
  }

  &--eraser {
    position: absolute;
    width: 89.65px;
    height: 61px;
    left: 0.56px;
    top: 166.62px;
  }

  &--eraser--lg {
    position: absolute;
    width: 122px;
    height: 99px;
    left: 3px;
    bottom: -25px;
  }

  &--pencil {
    position: absolute;
    width: 65.06px;
    height: 42.95px;
    left: 59px;
    top: 280.91px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .customer__review {
    padding: 16px 0;

    &--title {
      position: relative;
      z-index: 1;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      padding: 0 36px;
      margin-bottom: 16px;
    }

    &--list {
      flex-direction: column;
      gap: 20px;
      padding: 0 16px;

      .review__item {
        width: calc(100% - 16px);
        max-height: 200px;
        padding: 11px;

        &--customer {
          gap: 17px;
          margin-bottom: 7px;
          align-items: center;

          img {
            width: 31px;
            height: 31px;
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
          }
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

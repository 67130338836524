@use '../scss/' as *;

.feedback {

    h4 {
        font-size: 32px;
        line-height: 34px;
        font-weight: $weight-bold;
        margin-bottom: 40px;
        text-align: center;

        @include respond(phone) {
            font-size: 18px;
            font-weight: $weight-normal;
            line-height: 19px;
            margin-bottom: 12px;
        }
    }

    .cards {
        display: flex;
        gap: 100px;

        @include respond(phone) {
            gap: 24px;
        }

        .card {

            @include respond(phone) {
                display: none;
            }
        }

    }

    .card {
        width: 535px;
        height: 300px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        border-radius: 20px;
        background-color: #F2F5FF;
        padding: 32px;

        @include respond(phone) {
            padding: 8px;
            width: 190px;
            height: 179px;
            gap: 8px;
            margin: 0 auto;
        }

        .header {
            display: flex;
            gap: 33px;

            @include respond(phone) {
                gap: 14px;        
            }

            img {
                border: 1px solid #6986EF;
                border-radius: 50%;

                @include respond(phone) {
                    width: 27px;
                    height: 27px;
                }
            }

            .name {
                p {
                    font-size: 32px;
                    font-weight: $weight-bold;
                    line-height: 34px;
                    color: $main-color;
                    margin-bottom: 4px;

                    @include respond(phone) {
                        font-size: 14px;
                        line-height: 15px;
                        font-weight: $weight-normal;
                        margin-bottom: 2px;
                    }
                }

                span {
                    font-size: 20px;
                    font-weight: 400;
                    color: #828282;

                    @include respond(phone) {
                        font-size: 12px;
                        line-height: 13px;
                    }
                }
            }
        }

        .content {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;

            @include respond(phone) {
                font-size: 12px;
                line-height: 17px;
            }
        }
    }

    .card-mb {
        display: none;
        text-align: start;  
    
        @include respond(phone) {
            display: block;
        }
    }
}
.container {
  width: 100%;
  padding: 60px 0px 100px;
}
.title {
  font-weight: bold;
  font-size: 72px;
  line-height: 100px;
  text-align: center;
  color: #333333;
  margin-bottom: 64px;
}
.features {
  width: 100%;
  display: grid;
  grid-template-columns: calc(33.33% - 40px / 3) calc(33.33% - 40px / 3) calc(33.33% - 40px / 3);
  row-gap: 24px;
  column-gap: 20px;
  position: relative;
}
.right-features {
  display: grid;
  grid-template-columns: calc(50% - 20px / 2) calc(50% - 20px / 2);
  row-gap: 24px;
  column-gap: 20px;
  position: relative;
}
.right-features-right,
.right-features-left {
  width: 100%;
  display: flex;
  align-items: center;
}
.right-features-left {
  justify-content: flex-end;
}
.right-features-right {
  justify-content: flex-start;
}
.right-features-left >div{
  max-width: 427px;
  height: 100%;
}
.right-features-right >div{
  max-width: 427px;
  height: 100%;
}
.feature-move-left {
  animation: move-left .4s ease forwards;
}
.feature-move-right {
  animation: move-right .4s ease forwards;
}
.right-icon,
.left-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  background: #4abb77;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  cursor: pointer;
}
.left-icon {
  right: unset;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
}
.wrap {
  width: 100%;
  height: 100%;
  background: #ffffff4d;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 28px 40px;
}
.wrap:hover {
  background: #ffffff80;
}
.order {
  position: relative;
  width: 100px;
  height: 100px;
}
.order > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 12;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  color: #fff;
  padding-top: 9px;
}
.order > div:last-child {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  filter: blur(100px);
}
.content {
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #333333;
  margin-top: 15.73px;
}

@media (max-width: 1360px) {
  .wrap {
    padding: 28px 33px;
  }
}
@media (max-width: 1199px) {
  .title {
    font-size: 72px;
    line-height: 100px;
  }
  .wrap {
    padding: 28px 33px;
  }
  .content {
    font-size: 24px;
    line-height: 32px;
  }
  /* .order {
    width: 80px;
    height: 80px;
  }
  .order > div:first-child {
    font-size: 32px;
    line-height: 32px;
    width: 80px;
    height: 80px;
  } */
}
@media (max-width: 700px) {
  .title {
    font-size: 42px;
    line-height: 50px;
  }
  .features,
  .right-features {
    grid-template-columns: calc(50% - 20px / 2) calc(50% - 20px / 2);
  }
}

@media (max-width: 500px) {
  .content {
    font-size: 20px;
    line-height: 40px;
    margin-top: 10px;
  }
  .features,
  .right-features {
    grid-template-columns: auto;
  }
  .order div:first-child{
    width: 70px;
    height: 70px;
    font-size: 35px;
  }
  .order {
    width: 70px;
    height: 70px;
  }
  /* .wrap {
      padding: 28px 33px;
    }
    .content {
      font-size: 24px;
      line-height: 32px;
    }
    .order {
      width: 80px;
      height: 80px;
    }
    .order > div:first-child {
      font-size: 32px;
      line-height: 32px;
      width: 80px;
      height: 80px;
    } */
}

@keyframes move-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes move-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}


.highlight__section {
    padding: 80px 0;

    .highlight__item {
        display: flex;
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .highlight__section {
        padding: 20px 0;
    }

    .highlight__item img {
        width: 100%;
        height: 100%;
    }
}

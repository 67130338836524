@import "../../../assets/styles/_variables";

.root {
  margin: 160px 110px 0;
  font-family: "Inter";
  span {
    overflow: unset;
  }
  @media only screen and (max-width: 1900px) {
    margin: 160px 0 0 0;
  }
  &__banner {
    background-image: url("../../../assets/images/Pricing/bigBackgroundStarPricing.png");
    background-size: contain;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
    border-radius: 20px;
    position: relative;
    margin-bottom: 80px;

    &__content {
      display: flex;
      align-items: center;
      &--imgPeople {
        z-index: 2;
        img {
          margin: 32px 0 32px 146px;
          @media only screen and (max-width: 1900px) {
            margin: 32px 0;
          }
        }
      }
      &--imgDecorative {
        position: absolute;
        top: 0;
        right: 0;
      }
      &--imgBlobMid {
        position: absolute;
        top: 15px;
        left: 225px;
        z-index: 1;
      }
      &--imgBlobBot {
        position: absolute;
        bottom: -5px;
        right: 300px;
      }
      &--des {
        margin: 100px 60px 1px 274px;
        @media only screen and (max-width: 1900px) {
          margin: 0 60px 0 80px;
          z-index: 9;
        }
        &--title {
          font-weight: 700;
          font-size: 32px;
          line-height: 52px;
          margin-bottom: 32px;
          color: $primary-color;
        }
        &--info {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          margin-bottom: 40px;
        }
        &--button {
          float: right;
        }
      }
    }
  }
  &__invite {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 110px;
    margin-bottom: 45px;
    @media only screen and (max-width: 1900px) {
      grid-column-gap: 40px;
    }
    &--left {
      min-height: 667px;
      background-image: url("../../../assets/images/Pricing/backgroundStarLeftPricing.png");
      height: auto;
      background-repeat: no-repeat;
      border-radius: 20px;
      background-color: #ffffff;
      box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
      &--leftContent {
        min-height: 520px;
        margin: 32px 45px 0 45px;
        @media only screen and (max-width: 1900px) {
          margin: 32px 20px 0 20px;
        }
        h1 {
          text-align: center;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-bottom: 32px;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
        }
        div {
          margin-bottom: 40px;
          font-size: 20px;
          font-weight: 500;
          line-height: 51.5px;
          display: inline-block;
          @media only screen and (max-width: 1900px) {
            font-size: 16px;
          }
          span {
            display: initial;
          }
          span:nth-child(2) {
            font-weight: bold !important;
            font-size: 30px !important;
            margin: 0 5px;
            @media only screen and (max-width: 1900px) {
              font-size: 25px !important;
            }
          }
        }
      }
    }
    &--right {
      background-image: url("../../../assets/images/Pricing/backgroundStarRightPricing.png");
      height: 100%;
      background-repeat: no-repeat;
      border-radius: 20px;
      background-color: #ffffff;
      box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
      // padding-left: 214px;
      @media only screen and (max-width: 1900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
      }
      h1 {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin: 32px 0;
        text-align: center;
      }
      &--rightContent {
        padding-left: 214px;
        div {
          margin-bottom: 32px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 20px;
          line-height: 20px;
          color: $primary-color;
          @media only screen and (max-width: 1900px) {
            font-size: 16px;
          }
          img {
            margin-right: 48px;
          }
        }
        div:last-child {
          margin-bottom: 40px;
        }
        &--icon {
          margin-left: 16px;
        }
      }
    }
    &--button {
      float: right;
      margin: 0 80px 61px 0;
    }
  }
}
@media only screen and (max-width: 599px) {
  .root {
    margin: 69px 16px 0;
    button {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      padding: 8px 12px;
    }
    &__banner {
      background-image: url("../../../assets/images/Pricing/backgroundBannerM.svg");
      margin-bottom: 20px;
      background-size: auto;
      &__content {
        flex-direction: column;
        &--imgPeople {
          display: flex;
          justify-content: center;
          img {
            margin: 16px 0 20px 0;
            width: 262px;
          }
        }
        &--imgDecorative {
          img {
            width: 182px;
          }
        }
        &--imgBlobMid {
          top: 28%;
          left: 55%;
          transform: translate(-50%, -50%);
          z-index: 1;
          img {
            width: 207px;
          }
        }
        &--imgBlobBot {
          display: none;
        }
        &--des {
          margin: 0;
          &--title {
            margin: 0 21px 15px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            padding: 0 20px;
            br {
              display: none;
            }
          }
          &--info {
            font-size: 14px;
            line-height: 21px;
            margin: 0 13px 17px;
          }
          &--button {
            float: unset;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &__invite {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      &--left {
        background-image: url("../../../assets/images/Pricing/backgroundStarLeftPricingM.svg");
        height: 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
        margin-bottom: 20px;
        min-height: auto;
        &--leftContent {
          margin: 16px 24px 0 24px;
          min-height: auto;
          h1 {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          div {
            margin-bottom: 36px;
            line-height: 23px;
            span:nth-child(2) {
              font-size: 20px !important;
            }
            h5 {
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 4px;
            }
          }
        }
      }
      &--right {
        background-image: url("../../../assets/images/Pricing/backgroundStarRightPricingM.svg");
        height: auto;
        background-repeat: no-repeat;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
        width: 100%;
        h1 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin: 16px 0;
          text-align: center;
        }
        &--rightContent {
          margin: 16px 24px 4px 0;
          padding: 0;

          div {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 21px;
            color: $primary-color;
            img {
              margin-right: 16px;
              width: 23px;
            }
            img:last-child {
              width: 14px;
            }
          }
          &--icon {
            margin-left: 8px;
          }
          div:last-child {
            margin-bottom: 26px;
            align-items: unset;
          }
        }
      }
      &--button {
        margin: 0 0 16px 0;
        float: unset;
        display: flex;
        justify-content: center;
      }
    }
  }
}

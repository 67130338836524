.habit__tracker {
  max-width: 1430px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 57px;
  padding-top: 175px;
  margin-top: 123px;
  position: relative;
  justify-content: center !important;

  &--text {
    flex-basis: 40%;
  }

  &--img {
    img {
      height: 495px;
    }
  }

  &--star {
    position: absolute;
    width: 186px;
    height: 231.72px;
    left: 107%;
    top: 534.8px;
  }

  &--square--lg {
    position: absolute;
    width: 186px;
    height: 231.72px;
    left: -200px;
    bottom: -200px;
  }

  &--blob--tracker {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .habit__tracker {
    padding: 36px 16px 0 16px;
    margin-top: 0;
    flex-direction: column-reverse;
    gap: 20px;

    &--img {
      margin-top: 20px;
      img {
        width: 280px;
        height: auto;
        object-fit: cover;
      }
    }

    &--star {
      top: 7%;
      left: 60%;
      z-index: -1;

      img {
        width: 45px;
        height: 56px;
      }
    }

    &--square--lg {
      left: 88%;
      top: 44%;

      img {
        width: 65px;
        height: 65px;
      }
    }

    &--blob--tracker {
      position: absolute;
      display: block;
      left: 10px;
      top: 50%;
      z-index: -1;
    }
  }
}

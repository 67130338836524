.container {
    display: flex;
    justify-content: center;
    /* background: #1072BA; */
}

.max-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: transparent;
    max-width: 1442px !important;
    margin: 0 auto;
}

.brush {
    position: absolute;
    bottom: 57px;
    left: 75px;
    height: 75px;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 80px;
    color: #000000;
}

.content {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    width: 672px;
}

.wrap {
    display: flex;
    width: 100%;
    margin: 154px 0 172px 0;
}

.left {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
}

.wrap-phone {
    width: 90%;
}

.phone {
    width: 100%;
}

.white {
    width: 35%;
    position: absolute;
    left: 18%;
    top: 0px
}

.bg {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.wrap-left {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
}

.right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
}

.title-right {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
    color: #1B3765;
    position: relative;
}

.line {
    position: absolute;
    bottom: -14px;
    left: 30%;
}

.content-right {
    font-style: normal;
    font-size: 24px;
    line-height: 42px;
    color: #1B3765;
    margin-top: 30px;
}

.wrap-button {
    margin-top: 57px;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
}

.button1 {
    border-radius: 8px;
    height: 60px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-right: 60px;
    text-decoration: none;
    flex-wrap: nowrap;
    border: 1px solid;
}

.button1:hover {
    text-decoration: underline #000;
}

.button1 div {
    color: #000000;
    margin-left: 12px;
}

.button2 {
    border-radius: 8px;
    height: 60px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #000;
    background: #000;
    text-decoration: none;
}

.button2:hover {
    text-decoration: underline #FFF;
}

.button2 div {
    color: #FFFF;
    margin-left: 12px;
}

.wrap-button img {
    height: 100%;
}

@media (max-width:1500px) {
    .max-wrap {
        padding-left: 120px;
        padding-right: 120px;
    }
}

@media (max-width:1290px) {
    .max-wrap {
        padding: 20px 120px 50px;
    }

    .wrap-phone {
        width: 100%;
    }

}

@media (max-width:1024px) {
    .max-wrap {
        padding: 20px 80px 50px;
    }

    .wrap-left {
        width: 100%;
    }

    .title-right {
        font-size: 42px;
        line-height: 48px;
    }

    .content-right {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    .wrap-button {
        font-size: 16px;
    }


}


@media (max-width:800px) {
    .max-wrap {
        padding: 20px 24px 50px;
    }

    .wrap-left {
        width: 100%;
    }

    .title-right {
        font-size: 36px;
        line-height: 42px;
    }

    .content-right {
        font-size: 13px;
        line-height: 20px;
    }

    .wrap-button {
        font-size: 13px;
    }

    .button1 {
        margin-right: 20px;
    }

    .line {
        bottom: -4px;
        right: -10px;
        width: 50%;
    }

}

@media (max-width:600px) {
    .max-wrap {
        padding: 20px 16px 50px;
        margin-top: 0px;
    }

    .wrap {
        margin-top: 38px;
        margin-bottom: 78px;
    }

    .title {
        font-size: 16px;
        line-height: 22px;
    }

    .content {
        font-size: 8px;
        line-height: 10px;
        width: 90%;
        margin-top: 4px;
    }

    .wrap-phone {
        display: flex;
        align-items: center;
    }

    .phone {
        max-width: 148px;
    }

    .title-right {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .content-right {
        font-size: 10px;
        line-height: 14px;
        margin-top: 12px;
    }

    .wrap-button {
        font-weight: 500;
        font-size: 8px;
        line-height: 26px;
        margin-top: 20px;
        width: 100%;
    }

    .button1 {
        height: 26px;
        padding: 7px;
        margin-right: 15px;
        border-radius: 4px;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
    }

    .button1 div {
        margin-left: 3px;
    }

    .button2 {
        height: 26px;
        padding: 7px;
        border-radius: 4px;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
    }

    .button2 div {
        margin-left: 3px;
    }

    .right {
        padding-left: 10px;
    }

    .bg {
        display: none;
    }

    .white {
        width: 40%;
        left: 20%;
    }

    .left {
        padding-right: 0px;
    }

    .brush {
        bottom: -14px;
        left: 28px;
        height: 34px;
    }
}

@media (max-width:350px) {
    .max-wrap {
        padding: 20px 10px 50px;
    }

    .button1 {
        padding: 5px 5px;
        margin-right: 8px;
    }

    .button1 div {
        margin-left: 3px;
    }

    .button2 {
        padding: 5px 5px;
    }

    .button2 div {
        margin-left: 3px;
    }

    .right {
        padding-left: 0px;
    }
}

@media (max-width:300px) {
    .wrap-button img {
        display: none;
    }

    .button1,
    .button2 {
        min-width: auto;
    }
}

@media (min-width:1350px) {
    .wrap-left {
        max-width: 435px;
    }

    .left {
        justify-content: center;
    }
}
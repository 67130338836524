@use "../scss/" as *;

.services {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 0 16px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: 100px;

    @include respond(phone) {
      padding: 0;
    }

    &:first-child {
      .content {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      padding-left: 0;
      // padding-right: 100px;

      @include respond(phone) {
        padding-right: 0;
      }

      .content {
        margin-left: 53px;
        margin-right: 0;

        @include respond(phone) {
          margin-left: 9px;
        }
      }
    }

    .content {
      margin-right: 60px;

      @include respond(phone) {
        width: 166px;
        margin-right: 0;
      }

      h3 {
        font-size: 36px;
        font-weight: 600;
        line-height: 24px;
        color: $main-color;
        margin-bottom: 20px;

        @include respond(phone) {
          font-size: 16px;
          margin-bottom: 6px;
        }
      }

      p {
        font-size: 28px;
        font-weight: $weight-normal;
        line-height: 30px;
        max-width: 70%;

        @include respond(phone) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .img {
      position: relative;

      @include respond(phone) {
        width: 163px;
        height: 253px;
      }

      .phone {
        @include respond(phone) {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .google {
        position: absolute;
        top: -39px;
        left: 0;

        @include respond(phone) {
          width: 61px;
          height: 61px;
          top: 0;
          left: 17px;
        }
      }

      .outlook {
        position: absolute;
        top: 84px;
        right: -63px;

        @include respond(phone) {
          width: 63px;
          height: 63px;
          top: 63px;
          right: -5px;
        }
      }

      .office {
        position: absolute;
        bottom: 156px;
        left: -40px;

        @include respond(phone) {
          width: 69px;
          height: 69px;
          bottom: 55px;
          left: -5px;
        }
      }

      .yahoo {
        position: absolute;
        bottom: 0;
        right: -3px;

        @include respond(phone) {
          width: 61px;
          height: 61px;
          right: 17px;
        }
      }

      .inbox {
        position: absolute;
        top: 72px;
        left: -12px;

        @include respond(phone) {
          width: 124px;
          height: 40px;
          top: 38px;
          left: -2px;
          object-fit: cover;
        }
      }

      .message {
        position: absolute;
        top: 100px;
        left: 4px;

        @include respond(phone) {
          width: 146px;
          height: 46px;
          top: 51px;
          left: 15px;
          object-fit: cover;
        }
      }

      .cancel {
        position: absolute;
        top: 134px;
        left: -8px;

        @include respond(phone) {
          width: 116px;
          height: 26px;
          top: 62px;
          left: 10px;
          object-fit: cover;
        }
      }

      .setting {
        position: absolute;
        top: 170px;
        right: -24px;

        @include respond(phone) {
          width: 94px;
          height: 74px;
          top: 70px;
          right: 0;
          object-fit: cover;
        }
      }
    }
  }
}

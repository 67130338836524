.container {
  padding-left: 158px;
  padding-right: 119px;
  margin-top: 140px;

  display: flex;
}

.description {
  padding-top: 120px;
  padding-bottom: 216px;
  margin-left: 60px;

  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  line-height: 48px;
}

.description-app {
  display: none;
}

@media screen and (max-width: 1024px) {
  .container {
    padding-left: 70px;
  }

  .image {
    width: var(--imageWidth1024);
  }

  .description {
    padding-top: 80px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media screen and (max-width: 850px) {
  .container {
    flex-direction: column;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    margin-left: 0;
    padding-top: 20px;
    padding-bottom: 200px;

    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 769px) {
  .container {
    flex-direction: row;
    align-items: center;
    gap: 20px;

    margin-top: 40px;
    padding: 0 16px;
  }

  .description {
    display: none;
  }

  .description-app {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .image {
    width: 160px;
    height: 149px;
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* font-family: "Be Vietnam", sans-serif; */

  padding: 71px 120px 0;

  position: sticky;
  width: 100%;
  background-color: white;
  padding-bottom: 8px;
  left: 0;
  right: 0;

  transition: top 0.5s ease-in-out, display 0.5s ease-in-out;

  z-index: 999;
}

.header-placeholder {
  position: relative;
  /* height: 130px; */
  width: 100%;
}

.header-visible {
  top: -60px;
}

.header-hide {
  top: -200px;
}

.header-logo-container {
  padding-right: 16px;
}

.header-logo-image {
  max-width: 100%;
  max-height: 100%;
  min-width: 260px;
}

.header-routes-container {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.header-routes-container * {
  list-style-type: none;
}

.header-routes-item {
  padding-left: 26px;
  padding-right: 26px;
}

.header-routes-item:first-child {
  padding-left: 0;
}

.header-routes-item:last-child {
  padding-right: 0;
}

.route-item {
  color: #1b3765;
  text-decoration: none;
  font-size: 24px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

.route-item:hover {
  color: #71ba44;
  font-weight: bold;
}

.route-item__selected {
  color: #71ba44;
  position: relative;
  font-weight: 700;
}

.route-item__selected::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: #71ba44;
  bottom: -5px;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  left: calc(50% - 20px);
}

.header-menu-button,
.header-menu-checkbox {
  display: none;
}

.button-to-top {
  position: fixed;
  bottom: 40px;
  right: 4%;
  z-index: 999;
  transition: all 0.5s ease-in-out;
}

.button-to-top__hide {
  bottom: -100px;
}

.button-to-top button {
  border-radius: 50%;
  background: #1072ba;
  border: none;
  width: 56px;
  height: 56px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.button-to-top button img {
  width: 20px;
  height: 18px;
  transform: rotateZ(-90deg) translateX(0px);
  transition: all 0.3s ease-in-out;
}

.button-to-top button:hover img {
  transform: rotateZ(-90deg) translateX(6px);
}

.header-mobile-cover {
  display: none;
}

.header-routes ul ul {
  display: none;
  position: absolute;
  padding: 0;
}

.header-routes ul ul li {
  float: none;
}

.header-routes ul ul a {
  text-decoration: none;
  color: #1b3765;
}

.header-routes ul li:hover>ul {
  display: block;
  padding-top: 20px;
}

.header-routes ul li:hover a {
  color: #71ba44;
  font-weight: bold;
}

.route-sub-item {
  color: #1b3765;
  text-decoration: none;
  border-left: 3px solid #71ba44;
  border-radius: 5px;
  box-shadow: 0 4px 24px rgb(0 0 0 / 5%);
  padding: 10px;
  width: 100%;
  max-width: 180px;
  padding-right: 20px;
  display: block;
  text-align: center;
  margin-bottom: 5px;
  background: #ffffff;
}

.route-sub-item:hover {
  text-decoration: underline !important;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .header-logo-image {
    min-width: 220px;
  }

  .route-item {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .header-routes {
    max-width: 500px;
    width: 100%;
  }

  .header-routes-container {
    justify-content: space-between;
  }

  .header-routes-item {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media (max-width: 800px) {
  .header-routes-item {
    display: flex;
    position: initial;
    width: 100%;
    position: relative;
    right: 0;
    flex-direction: row-reverse;
    align-items: center;
    padding: 20px 0;
    line-height: 50px;
  }

  .header-routes ul ul {
    position: initial;
  }

  .header-routes ul li:hover>ul {
    padding: 0;
    top: 20px;
    right: 0;
    text-decoration: underline;
  }

  .header-routes ul ul {
    background-color: initial;
  }

  .header-routes ul li:hover .route-sub-item {
    text-decoration: none;
    border-left: none;
    border-radius: 5px;
    box-shadow: none;
    padding: 0;
    padding-right: 10px;
  }

  .routes-sub-item {
    font-size: 10px;
  }

  /* .button-to-top button:hover img {
    transform: none;
  } */
  .header-container {
    padding: 32px 24px 0;
    /* position: relative; */
  }

  /* .header-routes { */
  /* display: none; */
  /* } */

  .header-menu-button {
    display: block;
  }

  .header-menu-button label {
    padding: 8px 2px 8px 8px;
  }

  .header-logo-container {
    max-width: 37%;
  }

  .header-logo-image {
    min-width: 220px;
    max-width: 220px;
  }

  .header-routes-mobile {
    position: absolute;
    top: 98%;
    right: 0;
    text-align: right;
    overflow: hidden;
    width: 100%;
    max-height: 0px;
    background: white;
    transition: max-height 0.3s linear, max-width 0.3s linear;
  }

  .header-hide .header-routes-mobile {
    max-height: 0px !important;
  }

  .header-routes-container {
    flex-direction: column;
    padding-right: 24px;
  }

  .route-item {
    font-size: 16px;
    white-space: nowrap;
    /* line-height: 28px; */
  }

  .route-item__selected::after {
    left: unset;
    right: 0;
    width: 24px;
  }

  .header-menu-checkbox:checked+.header-routes-mobile {
    max-height: 250px;
  }

  .header-placeholder {
    height: 0;
  }

  .button-to-top {
    right: 24px;
    bottom: 17%;
  }

  .header-visible {
    top: -24px;
  }

  .button-to-top__hide {
    bottom: -50px;
  }
}

@media screen and (max-width: 450px) {
  .header-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-routes-container {
    gap: 10px;
  }

  .header-routes-item {
    flex-direction: column;
    align-items: flex-end;
    line-height: 20px;
    padding: 0;
  }

  .header-routes ul li:hover .route-sub-item {
    padding-right: 0;
  }

  .route-sub-item {
    text-align: right;
  }
}
@import "../../assets/styles/variables";

.tutorial {
  .tutorial__item {
    width: 356px;
    border-radius: $border-radius;
    box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);

    &--img {
      position: relative;
      img {
        margin-bottom: 12px;
        border-radius: 20px 20px 0 0;
      }
      .tutorial__item--svg {
        position: absolute;
        top: 32%;
        left: 39%;
        cursor: pointer;
      }
    }

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 52px;
      text-align: center;
      padding-bottom: 16px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .tutorial {
    margin-bottom: 0;
    h1 {
      margin-bottom: 0;
    }
    .tutorial__item {
      width: 100% !important;

      &--img {
        img {
          width: 100% !important;
        }

        .tutorial__item--svg {
          position: absolute;
          top: 30%;
          left: 30%;
        }
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 30px;
        padding-bottom: 9px;
      }
    }
  }
}

@import '../../assets/styles/variables';

.organize {
    margin-top: 81px;
    min-height: 110vh;
    position: relative;
    padding: 0 16px;
    background: url(../../assets/svg/banner-home.svg) no-repeat center center;
    background-size: cover;

    .organize__title {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 52px;
        color: $secondary-color;
        margin-bottom: 40px;
        padding-top: 60px;
    }

    .organize__text {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: $primary-color;
        margin-bottom: 58px;
    }

    .organize__download--flex {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        .organize__download--line {
            border: 2px solid #e7e7e7;
            height: 45px;
            border-radius: $border-radius;
        }
    }
}

@media only screen and (max-width: 1281px) {
    .organize {
        
        .organize__title {
            margin-bottom: 20px;
            padding-top: 30px;
        }

        .organize__text {
            font-size: 20px;
            line-height: 28px;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .organize {
        margin-top: 60px;
        min-height: 367px;

        .organize__title {
            font-size: 20px;
            line-height: 24px;
            padding-top: 6px;
            margin-bottom: 12px;
        }

        .organize__text {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 12px;
        }

        .organize__download--flex {
            z-index: 1;
            img,
            .organize__download--line {
                display: none;
                z-index: 1;
            }
        }
    }
}

.feature {
  padding: 80px 0 0 0;
  min-height: 410px;
  .feature__list {
    display: flex;
    margin-left: 111px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .feature {
    padding: 20px 0 0;
    min-height: auto;
    .feature__list {
      margin-left: 0 !important;
    }
    h1 {
      margin-bottom: 0 !important;
    }
  }
  .feature__title {
    margin-bottom: 0;
  }
}

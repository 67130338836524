@use "../scss/" as *;

.footer {
  padding: 40px 0;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 24px;
  }

  h3 {
    font-size: 32px;
    line-height: 34px;
    color: #fff;
    font-weight: 700;

    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
    }
  }

  .contact {
    display: flex;
    align-items: flex-start;
    margin-top: 48px;

    @media (max-width: 768px) {
      margin-top: 16px;
    }

    .form {
      p {
        font-size: 20px;
        font-weight: $weight-normal;
        color: #fff;
        line-height: 22px;
        margin-bottom: 24px;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 15px;
          margin-bottom: 16px;
        }
      }

      .input {
        width: 535px;
        background-color: #4d4d4d;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        color: #fff;

        @media (max-width: 768px) {
          width: 327px;
          font-size: 14px;
          line-height: 15px;
          border-radius: 6px;
        }
      }

      input {
        margin-bottom: 20px;
        padding: 20px;

        @media (max-width: 768px) {
          margin-bottom: 10px;
          padding: 10px;
        }
      }

      textarea {
        height: 160px;
        resize: none;
        padding: 20px;

        @media (max-width: 768px) {
          height: 132px;
          padding: 10px;
        }
      }

      button {
        margin-top: 40px;
        padding: 16px 58px;
        color: #fff;
        font-size: 24px;
        font-weight: $weight-normal;
        line-height: 26px;

        @media (max-width: 768px) {
          margin-top: 12px;
          padding: 8px 32px;
          font-size: 16px;
          line-height: 17px;
        }
      }
    }

    img {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}

.form_message {
  margin-top: 20px;
  &.error {
    color: red !important;
  }
}

.template {
    padding: 40px 0;
    overflow: hidden;
    margin-top: 205px;
    box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);

    .template__list {
        display: flex;
        gap: 48px;

        .template__item {
            box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .template {
        margin-top: 36px;
        padding: 23px 0;

        .template__list {
            gap: 10px;
        }

        .template__item {
            img {
                width: 100%;
            }
        }
    }
}

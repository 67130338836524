.monthly__planner {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1430px;
  margin: 0 auto;
  padding-top: 155px;
  gap: 93px;
  justify-content: center;

  &--img {
    flex-basis: 60%;
    display: flex;
    justify-content: end;
    img {
      height: 502px;
    }
  }

  &--text {
    flex-basis: 40%;
  }

  &--blob {
    position: absolute;
    left: -19.8%;
    right: 91.19%;
    top: 9.34%;
    bottom: 32.97%;
  }

  &--grass {
    position: absolute;
    width: 149px;
    height: 215px;
    left: 725px;
    top: 620px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .monthly__planner {
    padding: 20px 16px 0 16px;
    flex-direction: column;
    gap: 30px;

    &--img {
      margin-top: 20px;
      img {
        width: 280px;
        height: 200px;
      }
    }

    &--blob {
      z-index: -1;
      left: -10px;
      top: 50%;

      img {
        width: 77px;
        height: 75px;
      }
    }

    &--grass {
      top: 97%;
      left: 88%;
      img {
        width: 27px;
        height: 39px;
      }
    }
  }
}

:root {
  --pink: #ff61a9;
  --pink-gradient: linear-gradient(136.83deg, #ff5db0 3.15%, #ff8a65 96.7%);
  --purple-gradient: linear-gradient(67.51deg, #5a0389 -1.84%, #553bd2 106.46%);
  --imageWidth1024: 300px;
  --imageWidth450: 200px;
}

span {
  display: inline-block;
  overflow: auto;
}

.inline {
  display: inline;
  margin: 0;
}

.container {
  margin-top: 209px;
  padding: 0px 94px 0px 116px;
}

.ribbon-container {
  top: 0;
  right: 0px;
  position: absolute;
}

.ribbon-app {
  display: none;
}

.ellipse-1-container {
  position: absolute;
  left: 0px;
  top: 0px;
}

.header-wrap {
  position: relative;

  padding-top: 126px;
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 64px;
  line-height: 80px;

  padding-right: 70px;
  word-wrap: break-word;
  white-space: pre-line;

  width: 50%;
}

.header-description {
  font-size: 64px;
  line-height: 80px;
}

#test {
  display: inline-block;
}

#test {
  word-wrap: break-word;
  white-space: pre-line;
}

.pink-text {
  color: #ff6183;
}

.purple-gradient-text {
  background: var(--purple-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text {
  background: var(--pink-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.image-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.image-container > img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1360px) {
  .header-description {
    font-size: 56px;
    line-height: 70px;
  }
  .header-title {
    padding-right: 50px;
  }
}
@media screen and (max-width: 1279px) {
  .header-description {
    font-size: 48px;
    line-height: 60px;
  }
  .header-title {
    padding-right: 40px;
  }
}
@media screen and (max-width: 1120px) {
  .header-description {
    font-size: 40px;
    line-height: 50px;
  }
  .header-title {
    padding-right: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 70px;
  }

  .ribbon-container {
    top: 0;
    right: 0;
  }

  .ribbon {
    display: block;
    margin-left: auto;
    margin-right: 0;
    width: 80%;
    height: 80%;
  }

  .ellipse-1 {
    width: 80%;
    height: 80%;
  }
}

@media screen and (max-width: 850px) {
  .header-wrap {
    flex-direction: column;
    align-items: center;
  }

  .header-title {
    width: 100%;
    padding-right: 0px;
  }
  .image-container {
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 769px) {
  .ribbon {
    display: none;
  }

  .ribbon-app {
    display: block;
  }

  .ellipse-1-container {
    display: none;
  }

  .container {
    margin-top: 0;
    padding: 0 16px;
  }

  .header-wrap {
    justify-content: flex-start;
    padding-top: 82px;
  }

  /* .header-title { */
  /* width: 100%;
    font-size: 32px;
    line-height: 38px; */
  /* text-align: left; */
  /* } */

  .header-description {
    font-size: 36px;
    line-height: 42px;
  }

  .header-description strong {
    font-size: 32px;
    line-height: 38px;
  }

  #test {
    margin: 0;
  }

  .image-container {
    width: 100%;
    justify-content: flex-start;
  }

  .image-header {
    display: block;
  }
}

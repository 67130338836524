@import '../../assets/styles/variables';

.digital {
    position: relative;
    min-height: 90vh;

    .digital__content {
        display: flex;
        align-items: center;
        max-width: 1430px;
        margin: 0 auto;
        gap: 71px;
        justify-content: center;

        &--text {
            flex-basis: 40%;
        }

        &--img {
            flex-basis: 60%;
            img {
                height: 640px;
            }
        }
    }

    .digital__blob {
        position: absolute;
        left: 47%;
        right: 2.86%;
        top: 12.43%;
        bottom: 20.13%;
        z-index: -1;

        img {
            width: 693px;
            height: 840px;
        }
    }

    .digital__blob--left {
        position: absolute;
        left: -19.8%;
        right: 93.19%;
        top: -40%;

        img {
            width: 100%;
        }
    }

    .digital__blob--star {
        position: absolute;
        bottom: 15px;
        left: 35%;
    }

    .digital__blob--tree {
        position: absolute;
        width: 252.51px;
        height: 176.97px;
        right: 34px;
        bottom: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .digital {
        padding: 0 16px;
        min-height: auto !important;
        margin-bottom: 40px;
        .digital__content {
            padding-top: 40px;
            flex-direction: column-reverse;
            gap: 20px;

            &--img {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .digital__blob {
            display: none;
        }

        .digital__blob--star {
            left: 83%;
            top: 55%;

            img {
                width: 68px;
                height: 85px;
            }
        }

        .digital__blob--tree {
            top: 98%;
            left: 78%;
            img {
                width: 70px;
                height: 50px;
            }
        }
    }
}

@import "../../../assets/styles/_variables";
.root {
  font-family: "Inter";
  margin-top: 81px;
  margin-bottom: -140px;
  &__banner {
    background-image: url("../../../assets/images/Feature/bannerFeature.png");
    height: 100vh;
    background-repeat: no-repeat;
    max-height: 770px;
    background-size: cover;
    @media only screen and (max-width: 1900px) {
      background-size: cover;
      background-position: center;
      height: 85vh;
    }
    &--title {
      color: $btn-hover-color;
      padding: 60px 0 40px;
      text-align: center;
      font-weight: 700;
      font-size: 50px;
      line-height: 52px;
      color: #ffffff;
    }
    &--des {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      color: #e7e7e7;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 599px) {
  .root {
    margin-top: 0;
    margin-bottom: -16px;
    &__banner {
      background-image: url("../../../assets/images/Feature/bannerFeatureM.svg");
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      max-height: 375px;
      background-position: initial;
      &--title {
        padding: 64px 0 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
      &--des {
        margin: 0 40px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

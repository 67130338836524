.container {
    display: flex;
    justify-content: center;
}

.max-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: transparent;
    max-width: 1442px !important;
    gap: 82px;
    padding-bottom: 240px;
}

.brush {
    position: absolute;
    bottom: -35px;
    left: 275px;
    height: 60px;
}

.wrap {
    display: flex;
    width: 100%;
    position: relative;
}

.arrow {
    position: absolute;
    bottom: 52%;
    left: 100%;
    max-width: 250px;
}

.left {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.right-pic {
    justify-content: flex-start;
}

.wrap-phone {
    z-index: 99;
    max-width: 429px;
    display: flex;
    align-items: center;
}

.white {
    width: 35%;
    position: absolute;
    right: 18%;
    top: 0px
}

.phone {
    width: 100%;
}

.bg {
    position: absolute;
    bottom: 28px;
    width: 100%;
}

.wrap-left {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
}

.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.title-right {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
    color: #1B3765;
    position: relative;
    text-align: center;
}

.line {
    position: absolute;
    bottom: -15px;
    left: 30%;
    width: 180px;
}

.content-right {
    font-size: 24px;
    line-height: 42px;
    color: #1B3765;
    margin-top: 30px;
}

.wrap-button {
    margin-top: 60px;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
}

.button1 {
    border-radius: 8px;
    height: 60px;
    background-color: #000;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-right: 60px;
    text-decoration: none;
}

.button1:hover {
    text-decoration: underline #FFF;
}

.button1 div {
    color: #FFF;
    margin-left: 12px;
}

.button2 {
    border-radius: 8px;
    height: 60px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #000;
    text-decoration: none;
}

.button2:hover {
    text-decoration: underline #000;
}

.button2 div {
    color: #000;
    margin-left: 12px;
}

.wrap-button img {
    height: 100%;
}

@media (max-width:1500px) {
    .max-wrap {
        padding-left: 120px;
        padding-right: 120px;
    }

    .arrow {
        left: auto;
    }
}

@media (max-width:1290px) {
    .max-wrap {
        padding: 20px 120px 240px;
        margin-top: -144px;
    }

    .wrap-phone {
        width: 100%;
    }

    .arrow {
        width: 240px;
        /* left: 70%; */
    }

}

@media (max-width:1024px) {
    .max-wrap {
        padding: 20px 80px 50px;
        margin-top: -135px;
    }

    .wrap-left {
        width: 100%;
    }

    .arrow {
        width: 260px;
    }

    .title-right {
        font-size: 42px;
        line-height: 48px;
    }

    .content-right {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    .wrap-button {
        font-size: 16px;
    }

    .brush {
        bottom: -25px;
        left: 105px;
        height: 50px;
    }
}

@media (max-width:780px) {
    .wrap-phone {
        max-width: 130px;
    }

    .max-wrap {
        padding: 20px 24px 50px;
        margin-top: -115px;
    }

    .wrap-left {
        width: 100%;
    }

    .arrow {
        width: 150px;
        bottom: 85%;
        left: 60%;
    }

    .title-right {
        font-size: 36px;
        line-height: 42px;
    }

    .content-right {
        font-size: 13px;
        line-height: 20px;
    }

    .wrap-button {
        font-size: 13px;
    }

    .button1 {
        margin-right: 15px;
        flex-wrap: nowrap;
        min-width: 70px;
        width: 100%;
        justify-content: center;
    }

    .line {
        bottom: -5px;
        left: 30%;
        width: 60px;
    }

    .right-pic {
        padding-right: 10px;
    }
}

@media (max-width:600px) {
    .max-wrap {
        padding: 0px 17px 50px 24px;
        margin-top: -55px;
    }

    .title-right {
        font-size: 24px;
        line-height: 32px;

        br {
            display: none;
        }
    }

    .content-right {
        font-size: 10px;
        line-height: 14px;
        margin-top: 12px;
    }

    .wrap-button {
        font-weight: 500;
        font-size: 8px;
        line-height: 26px;
        margin-top: 20px;
        width: 100%;
    }

    .wrap-button img {
        height: 16px;
    }

    .button1 {
        height: 26px;
        padding: 7px;
        border-radius: 4px;
        flex-wrap: nowrap;
    }

    .button1 div {
        margin-left: 4px;
    }

    .button2 {
        height: 26px;
        padding: 7px;
        border-radius: 4px;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
    }

    .button2 div {
        margin-left: 4px;
    }

    .bg {
        display: none;
    }

    .white {
        width: 30%;
        right: 20%;
    }

    .right-pic {
        padding-right: 0px;
    }

    .brush {
        bottom: -14px;
        left: 28px;
        height: 28px;
    }

    /* .arrow {
        top: -20px;
        right: -9px;
        width: 90px;
    } */

    .arrow {
        width: 95px;
    }

}

@media (max-width:400px) {
    .max-wrap {
        margin-top: -45px;
    }

}

@media (max-width:350px) {
    .max-wrap {
        padding: 0px 10px 50px;
        margin-top: -39px;
    }

    .button1 {
        padding: 5px 5px;
        margin-right: 8px;
    }

    .button1 div {
        margin-left: 3px;
    }

    .button2 {
        padding: 5px 5px;
    }

    .button2 div {
        margin-left: 3px;
    }

    .title-right {
        font-size: 20px;
        line-height: 28px;
    }

    .content-right {
        margin-top: 8px;
    }

    .arrow {
        top: -24px;
        right: -9px;
        width: 80px;
    }

}

@media (max-width:300px) {
    .wrap-button img {
        display: none;
    }

    .max-wrap {
        margin-top: 0;
    }

    .arrow {
        width: 20%;
    }

    .button1 {
        min-width: 52px;
        width: auto;
    }
}

@media (min-width:1350px) {
    .wrap-left {
        max-width: 535px;
    }

    .left {
        justify-content: center;
    }
}
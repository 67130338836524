@import "../../../../assets/styles/variables";

.content {
  margin-bottom: 80px;
  font-family: "Inter";
  color: $primary-color;
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
    margin-bottom: 79px;
  }
  &__cardList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 114px;
    grid-row-gap: 78px;
    div[class*="ant-card-bordered"] {
      border-radius: 20px;
      box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
    }
    div[class*="ant-card-cover"] {
      img {
        border-radius: 20px 20px 0px 0px;
      }
    }
    div[class*="ant-card-body"] {
      padding: 20px 20px 42px;
      div[class*="ant-card-meta"] {
        div[class*="ant-card-meta-detail"] {
          div[class*="ant-card-meta-title"] {
            font-weight: 700;
            font-size: 20px;
            line-height: 52px;
            text-align: center;
            color: #000000;
            margin-bottom: 8px;
            font-family: "Inter";
          }
          div[class*="ant-card-meta-description"] {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $primary-color;
            font-family: "Inter";
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .content {
    margin-bottom: 20px;
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    &__cardList {
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: 16px;
      margin: 0 32px;
      div[class*="ant-card-bordered"] {
        border-radius: 10px;
      }
      div[class*="ant-card-cover"] {
        img {
          border-radius: 10px 10px 0px 0px;
        }
      }
      div[class*="ant-card-body"] {
        padding: 12px 17px 16px;
        div[class*="ant-card-meta"] {
          div[class*="ant-card-meta-detail"] {
            div[class*="ant-card-meta-title"] {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
            }
            div[class*="ant-card-meta-description"] {
              font-size: 12px;
              line-height: 18px;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}

@import "../../assets/styles/variables";

.video__advertise {
  background-image: url("../../assets/svg/customer-bg.svg");
  padding: 60px 0;
  margin-bottom: 60px;

  &--title {
    margin-bottom: 80px;
  }

  .video__container--item {
    max-height: 365px;
    border-radius: $border-radius;
  }

  &--list {
    max-width: 1268px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .video__advertise--item {
      cursor: pointer;
      position: relative;
      .video__advertise--play {
        position: absolute;
        top: 38%;
        left: 39%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .video__advertise {
    padding: 16px 0;
    margin-bottom: 20px;
    background-image: url("../../assets/svg/customer-bg-mobile.svg");
    &--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
    }

    &--list {
      padding: 0 17px;
      .video__advertise--item {
        img {
          width: 96px;
          height: 96px;
        }

        .video__advertise--play {
          position: absolute;
          top: 35%;
          left: 38%;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .video__container--item {
      max-height: 96px;
      border-radius: $border-radius;
    }
  }
}

@use '../scss/' as *;

.banner {
    margin: 60px 100px 0;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 40px;

    @include respond(phone) {
        margin: 0 16px;
        gap: 8px;
    }
    
    .logo {
        width: 146px;
        height: 146px;
        object-fit: contain;

        @include respond(phone) {
            width: 64px;
            height: 64px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include respond(phone) {
            gap: 8px;
        }

        h1 {
            font-size: 50px;
            font-weight: $weight-bold;
            color: $main-color;
            line-height: 54px;

            @include respond(phone) {
                font-size: 20px;
                line-height: 22px;
            }
        }

        h4 {
            font-size: 30px;
            font-weight: 400;
            line-height: 32px;

            @include respond(phone) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .actions {
        width: 313px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;

        .social {
            display: flex;
            align-items: center;
            gap: 24px;

            @include respond(phone) {
                display: none;
            }

            p {
                font-size: 20px;
                font-weight: $weight-normal;
                line-height: 21.5px;
            }

            .social-icons {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
}
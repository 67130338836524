.wrap-container {
  width: 100%;
  background: #333333;
  display: flex;
  justify-content: center;
  font-family: Agrandir;
}
.container {
  width: 100%;
  padding: 48px 60px 56px;

  max-width: 1440px;
  position: relative;
}
.title > a {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
}
.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.contact {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact > div {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 16px;
  opacity: .9;
}
.contact > input {
  background: #ffffff20;
  /* opacity: .2; */
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  height: 70px;
  padding: 11px 18px;
  outline: none;
  color: #fff;
  font-size: 20px;
  border: none;
  font-family: Agrandir;
}
.contact > input::placeholder,
.contact > textarea::placeholder {
  color: #fff;
  opacity: 0.4;
  font-size: 20px;
  font-family: Agrandir;
}
.contact > textarea {
  background: #ffffff20;
  /* opacity: 0.2; */
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;
  height: 170px;
  padding: 11px 18px;
  outline: none;
  color: #fff;
  font-size: 20px;
  border: none;
  font-family: Agrandir;
}

.footer {
  padding: 16px 0px 17px;
  width: 40%;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.paths {
  display: flex;
}
.paths > div {
  display: flex;
  flex-direction: column;
}
.paths > div:first-child {
  margin-right: 142px;
}
.paths > div > div {
  font-weight: bold;
}
.paths > div > div {
  font-weight: bold;
  margin-bottom: 22px;
  line-height: 1;
}
.paths > div > a {
  margin-bottom: 12px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  opacity: .9;
}

.foot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}
.btn {
  width: 50%;
}
.btn > div {
  background: #4abb77;
  border-radius: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1px;
  color: #333333;
  width: 160px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  cursor: pointer;
  font-family: Agrandir-Medium;
}
.btn-mobile {
  display: none;
}
.copyright {
  width: 50%;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  opacity: .9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > img {
  position: absolute;
  width: 156px;
  height: 116px;
  left: 269px;
  top: -70px;
}

.imageWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
}

@media (max-width: 1199px) {
  .container {
    padding: 48px 24px 56px;
  }
}
@media (max-width: 900px) {
  .paths > div:first-child {
    margin-right: 0px;
  }
  .paths {
    justify-content: space-between;
  }
  .container > img {
    left: 100px;
    top: -70px;
  }
}
@media (max-width: 600px) {
  .btn-mobile {
    margin-top: 40px !important;
    display: block;
  }
  .copyright {
    width: 100%;
  }
  .hide {
    display: none;
  }
  .wrap {
    flex-direction: column;
    justify-content: center;
  }
  .contact {
    width: 100%;
  }
  .footer {
    width: 100%;
    margin-top: 30px;
  }
  .paths > div:first-child {
    margin-right: 62px;
  }
  .paths {
    justify-content: flex-start;
  }
  .container > img {
    width: 109.199px;
    height: 81.199px;
    left: 50px;
    top: -49px;
  }
  .imageWrapper {
    display: none;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 28px 14px 36px;
  }
}

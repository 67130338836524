.container {
  padding-left: 121px;
  padding-right: 168px;
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
  gap: 83px;
}

.content {
  padding-top: 130px;

  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.content-app {
  display: none;
}

.image {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .container {
    padding-left: 70px;
  }

  .content {
    padding-top: 100px;

    font-size: 28px;
    line-height: 42px;
  }

  .image {
    width: var(--imageWidth1024);
  }
}

@media screen and (max-width: 850px) {
  .container {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
    padding: 0;

    padding: 0 64px;
  }

  .content {
    text-align: center;
    padding-top: 0;

    font-size: 20px;
    line-height: 30px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 769px) {
  .container {
    margin-top: 40px;
    padding: 0 16px;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    display: none;
  }

  .content-app {
    display: block;

    font-size: 14px;
    line-height: 20px;
  }

  .image {
    width: 148px;
    height: 163px;
  }
}

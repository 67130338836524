@import "../../assets/styles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 7px 110px;
  background: $white-color;
  box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
  top: 0;
  z-index: 9999;

  @media only screen and (max-width: 1900px) {
    padding: 7px 0;
  }
  .header__logo {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  .header__navigation ul {
    display: flex;
    align-items: center;
    gap: 56px;
    @media only screen and (max-width: 1900px) {
      gap: 40px;
    }
    li {
      list-style: none;
    }

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $primary-color;
      text-decoration: none;
      @media only screen and (max-width: 1900px) {
        font-size: 16px;
      }
      &.active {
        color: $secondary-color;
      }
    }
  }

  .header__menu {
    display: none;
  }

  .header__right {
    display: flex;
    align-items: center;
    gap: 88px;
    @media only screen and (max-width: 1900px) {
      button {
        font-size: 16px;
      }
    }
    &--select {
      display: flex;
      align-items: center;
      gap: 27px;
      cursor: pointer;
      position: relative;
      width: 130px;

      .select__lang {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        font-size: 20px;
        @media only screen and (max-width: 1900px) {
          font-size: 16px;
        }
      }

      .select__arrow--active {
        img {
          transform: rotate(180deg);
        }
      }

      .options {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        overflow: hidden;
        animation-duration: 0.3s;
        animation-name: dropdown-animation;
        animation-fill-mode: forwards;
        background-color: $white-color;
        padding: 16px;
        border-radius: $border-radius;
        display: flex;
        gap: 20px;
        flex-direction: column;
        box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);

        .option {
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          cursor: pointer;
        }
      }

      @keyframes dropdown-animation {
        from {
          max-height: 0;
        }
        to {
          max-height: 400px;
        }
      }

      .selected {
        color: $secondary-color;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .header {
    left: 0;
    padding: 8px 16px;

    .header__res {
      display: block !important;
    }

    .header__logo {
      gap: 8px;
      align-items: center;

      &--img {
        width: 32px;
        height: 32px;
      }

      &--img--slogan {
        width: 171px;
        height: 21px;
      }
    }
    .header__navigation,
    .header__right {
      display: none !important;
    }

    .overlay {
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9;
    }

    .header__menu {
      display: block;
      cursor: pointer;
      padding: 8px;
      position: absolute;
      right: 0;
      background: $white-color;
      border-radius: 10px;
      z-index: 99;

      & .header__menu--show--icon,
      .header__menu--hide--icon {
        position: absolute;
        right: 16px;
        top: -21px;
      }

      & .header__menu--show--icon {
        filter: invert(100%);
      }

      .select__arrow--lang {
        top: 0;
        transform: rotate(-90deg);
        width: 20px;
        height: 20px;
      }

      .header__navigation--mobile {
        ul {
          display: flex;
          flex-direction: column;
          gap: 16px;

          li {
            font-size: 16px;
            font-weight: 600;
            line-height: 23.68px;
          }
        }
      }

      .header__right--mobile {
        .header__right--btn {
          padding: 16px 0;

          a {
            color: #ffce00;
            font-size: 16px;
            font-weight: 600;
            line-height: 23.68px;
            text-transform: uppercase;
            text-decoration-line: underline;
          }
        }
      }

      .header__right--select {
        gap: 8px;
        & div:nth-child(2) {
          display: flex;
        }
      }
    }
  }
}

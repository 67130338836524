@import "../../assets/styles/variables";

.feature__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 380px;
  height: 100%;
  border-radius: $border-radius;
  box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
  background: #ffffff;
  height: 373px;

  img {
    padding: 40px 0 12px 0;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 52px;
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding: 0 20px 60px 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .feature__item {
    width: 100% !important;
    height: 100%;
    box-shadow: 0px 1.7017297744750977px 25.52594757080078px 2.127162218093872px
      rgba(4, 6, 15, 0.05);

    img {
      width: 40px;
      padding: 16px 0 8px 0;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }

    p {
      font-size: 12px;
      line-height: 21px;
      text-align: center;
    }
  }
}

.footer-container {
  padding: 88px 120px 32px;
  /* font-family: Be Vietnam; */
  background-color: #1072ba0d;
  position: relative;
}

.footer-container h4 {
  margin: 0;
}

.footer-quick-link {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 200px;
  justify-content: center;
}

.footer-block {
  padding-bottom: 40px;
}

.footer-block-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #1b3765;
  padding-bottom: 32px;
}

/* .footer-block-links {
} */

.block-links-container,
.block-links-container .block-links-item {
  list-style-type: none;
}

.block-links-container {
  padding: 0;
  margin: 0;
}

.label-email-error,
.block-links-item {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #1b3765;
  padding-bottom: 32px;
}

.label-email-error {
  color: red;
  padding-left: 16px;
}

/* .block-links-item:last-child {
  padding-bottom: 0;
} */

.block-links-item a {
  color: inherit;
  text-decoration: none;
}

.copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #1b3765;
  margin-top: 40px;
}

.footer-form {
  background: white;
  border-radius: 8px;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-container input {
  padding: 15px 0 15px 16px;
  border: none;
  border-radius: 8px 0 0 8px;
  width: calc(100% - 100px);
}

.form-container input:focus-visible {
  outline: none;
}

.form-container input,
.form-container input::placeholder {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
}

.form-container input::placeholder {
  color: #666666;
}

.form-container button {
  border-radius: 8px;
  background: #1072ba;
  border: none;
  width: 78px;
  height: 56px;
}

.form-container button:hover {
  cursor: pointer;
}

.form-container button img {
  width: 20px;
  height: 18px;
}

.footer-decor-scratch {
  position: absolute;
  width: 59px;
  height: 78px;
  left: 295px;
  top: -39px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .footer-block-title {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1350px) {
  .footer-quick-link {
    flex-wrap: wrap;
  }

  /* .footer-block {
    width: 25%;
    flex-basis: 25%;
  } */

  /* .footer-block:last-child {
    width: 100%;
    flex-basis: 100%;
  } */
  .footer-block-title {
    font-size: 20px;
  }

  .label-email-error,
  .block-links-item {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1250px) {
  .footer-block {
    width: 25%;
    flex-basis: 25%;
  }

  .footer-block:last-child {
    width: 100%;
    flex-basis: 100%;
  }

  .footer-form {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .footer-container {
    padding: 32px 26px 40px 32px;
  }

  .footer-block {
    width: 50%;
    flex-basis: 50%;
    padding-bottom: 22px;
  }

  .footer-form {
    border-radius: 4px;
    width: 100%;
  }

  .footer-block:last-child {
    width: 100%;
    flex-basis: 100%;
  }

  .footer-quick-link {
    flex-wrap: wrap;
    gap: 29px;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .footer-block-title {
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }

  .label-email-error,
  .block-links-item {
    padding-bottom: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  .form-container input,
  .form-container input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  .form-container input {
    padding: 8px 12px;
    border-radius: 4px 0 0 4px;

    width: calc(100% - 70px);
  }

  .form-container button {
    border-radius: 4px;
    background: #1072ba;
    border: none;
    padding: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .form-container button img {
    width: 15.5px;
    height: 13.5px;
  }

  .copyright {
    font-size: 10px;
    line-height: 14px;
    position: absolute;
  }

  .footer-decor-scratch {
    width: 27px;
    height: 35px;
    left: 35px;
    top: -17px;
  }
}

@media (max-width: 350px) {
  .footer-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@import "../../assets/styles/_variables";

.footer {
  background-color: $primary-color;
  color: $white-color;
  background-image: url("../../assets/svg/footer_img.svg");

  .footer__list {
    max-width: 1430px;
    margin: 0 auto;
    padding: 60px 0 71px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &--download {
      min-height: 186px;
      p {
        margin-bottom: 35px !important;
      }

      a {
        color: $white-color;
      }
    }

    &--product {
      a {
        color: $white-color;
      }
    }

    &--support {
      a {
        color: $white-color;
      }
    }

    h5,
    .footer__social--title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
      }
    }

    &--contact {
      flex-basis: 40%;
    }

    .footer__social {
      h5 {
        margin-top: 8px;
      }

      &--list {
        display: flex;
        gap: 32px;
        img {
          cursor: pointer;
        }
      }
    }
  }

  .footer__contact--flex {
    display: flex;
    align-items: center;
    gap: 40px;

    .footer__contact--input {
      flex-basis: 66%;
      display: flex;
      flex-direction: column;
    }

    .btn__send {
      flex-basis: 20%;
      padding: 2px 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 52px;
      // margin-top: 12px;
    }
  }

  .input_border {
    border: none;
    border-bottom: 1px solid $white-color;
    background-color: transparent;
    padding-bottom: 16px;
    margin-bottom: 32px;
    color: $white-color;
    &:focus {
      outline: none;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .footer {
    padding: 0 25px 32px 25px;
    background-image: url("../../assets/svg/bg-footer-mb.svg");
    .footer__list {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 18px 0 0 0;

      &--download {
        min-height: auto;
        width: 33.33%;
        p {
          margin-bottom: 11px !important;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 11px;
      }

      p {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 12px !important;
      }
      &--contact {
        flex-basis: 100%;
      }
    }

    .footer__contact--flex {
      align-items: flex-end;
      gap: 20px;

      .footer__contact--input {
        width: 100%;
        flex-basis: 100%;
        input:nth-child(2) {
          margin-bottom: 0 !important;
        }
      }

      .btn__send {
        margin: 0 0 8px 0;
        padding: 10px 18px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 14px;
      }
    }
    .footer__contact {
      margin: 4px 0 16px 0;
    }

    .footer__social {
      &--title {
        text-align: center;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 21px !important;
        margin-bottom: 12px !important;
      }
      &--list {
        gap: 18px !important;
        justify-content: center;
        margin-left: 16px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .input_border {
      font-size: 10px;
      padding-bottom: 10px;
      margin-bottom: 16px;
    }
  }
}

.template {
	overflow: hidden;
	margin-top: 23px;
	// height: 191px;

	.template__list {
		display: flex;
		gap: 48px;
		height: 227px;
		.mySwiper {
			overflow-x: scroll;
		}
		.template__item {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@use "./scss/" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: $text-color;
}

button {
  border: none;
  outline: none;
  padding: 16px 30px;
  background-color: $main-color;
  color: $white-color;
  border-radius: 12px;
  font-size: 20px;
  line-height: 24px;
  font-weight: $weight-bold;
  font-family: $font-secondary;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  a {
    color: $white-color;
  }
  &:hover {
    background-color: #001636;
  }

  @include respond(phone) {
    padding: 5px 36px;
    border-radius: 6px;
    font-size: 16px;
  }
}

input,
textarea {
  outline: none;
  border: none;
}

body {
  position: relative;
  font-family: $font-primary;
  margin: 0;
}

.container {
  position: relative;
  max-width: 1170px;
  color: $text-color;
  margin: auto;
  overflow-x: hidden;

  .body {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-top: 104px;
    margin-bottom: 202px;

    @include respond(phone) {
      margin-bottom: 24px;
      gap: 24px;
    }
  }
}

.bg-footer {
  background-color: #282828;
  width: 100%;
  height: 630px;
  position: absolute;
  bottom: -20px;
  z-index: 0;

  @include respond(phone) {
    background-color: transparent;
    bottom: 0;
    height: 339px;
  }

  .image-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: none;

    @include respond(phone) {
      display: block;
    }
  }
}

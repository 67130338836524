.weekly__planner {
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 1420px;
    margin: 0 auto;
    padding-top: 120px;
    justify-content: center !important;

    &--text {
        flex-basis: 40%;
    }

    &--img {
        img {
            height: 528px;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .weekly__planner {
        padding: 20px 16px 0 16px;
        flex-direction: column-reverse;

        &--img {
            margin-top: 20px;
            img {
                width: 322px;
                height: 262px;
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@font-face {
    font-family: "Mazzard soft H";
    src: url(../../../fonts/MazzardSoftH-Medium.otf);
}

$font-primary: "Mazzard soft H";
$font-secondary: 'Inter',sans-serif;

$main-color: #6986EF;
$text-color: #333333;
$white-color: #fff;
$black-color: #000;
$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$weight-bold: 700;
$weight-normal: 500;

$red-light: #FFE7E6;
$yellow-light: #FFF7D5;
$sprint-green-light: #DEFFFA;
$blue-light: #E6F5FF;
$green-light: #EEFFF1;
$purple-light: #F6EEFF;

$red: #EC605B;
$yellow: #E8CF64;
$sprint-green: #67C9B9;
$blue: #64ACDB;
$green: #72D081;
$purple: #AA76DE;

$mobile-width: 768px;
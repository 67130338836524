.container {
    display: flex;
    justify-content: center;
}

.max-wrap {
    padding: 124px 0 85px;
    max-width: 1442px;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 88px;
    color: #71BA44;
}

.subtitle {
    color: #71BA44;
    font-size: 40px;
    line-height: 59.2px;
}

.wrap {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    margin-top: 64px;
}

.left {
    width: 55%;
}

.right {
    width: 45%;
    position: relative;
}

.question-mark {
    position: absolute;
    right: 0px;
    top: -10%;
    height: 120px;
}

.man {
    width: 100%;
}

.content {
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 38.48px;
    color: #1B3765;
}

@media (min-width:1920px) {
    .man {
        width: 80%;
    }
}

@media (max-width:1500px) {
    .max-wrap {
        padding-left: 120px;
        padding-right: 120px;
    }
}

@media (max-width:1024px) {

    .max-wrap {
        padding: 90px 80px 0px;
    }

    .title {
        font-size: 42px;
        line-height: 48px;
    }

    .content {
        font-size: 16px;
        line-height: 24px;
    }
}


@media (max-width:780px) {
    .wrap {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 28px;
    }

    .left {
        width: 100%;
    }

    .right {
        margin-top: 61px;
        width: 100%;
    }

    .question-mark {
        top: -10%;
        height: 60px;
    }

    .max-wrap {
        padding: 30px 24px 0px;
    }

    .title {
        font-size: 28px;
        line-height: 40px;
        width: 100%;
    }

    .subtitle {
        font-size: 20px;
        line-height: 29.6px;
        margin-top: 12px;
    }

    .content {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width:350px) {

    .max-wrap {
        padding: 90px 10px 0px;
    }
}
@import "../../assets/styles/_variables";

.library__template {
  position: relative;
  padding-bottom: 80px;
  overflow: hidden;
  &--list {
    max-width: 1430px;
    margin: 0 auto;
    display: flex;
    gap: 80px 106px;
    flex-wrap: wrap;
    justify-content: space-between;

    .library__template--item {
      flex: 1 1;
      background-color: $white-color;
      padding-bottom: 40px;
      box-shadow: 0px 4px 60px 5px rgba(4, 6, 15, 0.05);
      border-radius: 20px;

      img {
        margin-bottom: 24px;
      }

      h5 {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 52px;
        text-align: center;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        padding: 0 24px;
      }
    }
  }

  &--star {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &--grass {
    position: absolute;
    bottom: 0;
    right: 85px;
    z-index: 3;
    img {
      width: 87px;
      height: 125px;
    }
  }

  &--blob--left {
    position: absolute;
    left: -10px;
    top: 23%;
  }
}

@media only screen and (max-width: 1900px) {
  .library__template {
    &--list {
      max-width: 90%;
      margin: 0 auto;
    }
    .library__template--item {
      width: 45%;
      flex: unset !important;
      z-index: 2;

      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .library__template {
    padding: 0 32px 20px 32px;
    &--list {
      flex-direction: column;
      gap: 24px 106px;
      max-width: 100%;
      .library__template--item {
        width: 100%;
        padding-bottom: 16px;
        border-radius: 20px;

        img {
          width: 100%;
          margin-bottom: 12px;
        }

        h5 {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          padding: 0 16px;
        }
      }
    }

    &--star {
      top: 20%;
      right: 0;
      z-index: -1;

      img {
        width: 115px;
        height: 143px;
      }
    }

    &--blob--left {
      top: 5%;
      left: -70px;
      z-index: -1;
    }

    &--grass {
      right: 3px;
      bottom: 0;

      img {
        width: 25px;
        height: 36px;
      }
    }
  }
}

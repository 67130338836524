@import "../../../assets/styles/_variables";
button {
	font-weight: 600;
}
.root {
	margin-top: 16px;
	padding: 0 16px;

	&__btn--more {
		padding: 0 !important;
		background-color: transparent;
	}

	h3 {
		font-size: 16px;
		font-weight: 600;
		color: #333;
		line-height: 20px;
	}

	&__appbar {
		width: 100%;
		height: 24px;
		display: flex;
		justify-content: space-between;

		&--img {
			width: 24px;
			height: 24px;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;

		&__img {
			width: 88px;
			height: 88px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__cate {
		width: 100%;
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&--item {
			padding: 8px 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;
			position: relative;

			h4 {
				font-size: 16px;
				font-weight: 500;
				color: #333;
			}

			p {
				color: #828282;
				font-size: 10px;
				font-weight: 400;
			}

			&--star {
				height: 12px;
				display: flex;
				align-items: center;
			}

			&::after {
				content: "";
				width: 1px;
				height: 17px;
				background-color: #e0e0e0;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}

			&:last-child::after {
				content: "";
				display: none;
			}
		}
	}

	&__btn {
		width: 100%;
		margin: 16px 0 23px;

		button {
			width: 100%;
			padding: 14px;
			background-color: #ff2e2e;
			border-radius: 8px;
			line-height: 20px;
		}
	}

	&__description {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 24px;
		overflow: hidden;
		position: relative;

		&--suptitle {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;

			&--base {
				height: 60px;
				color: #000;
				position: relative;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: pre-wrap;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				border-radius: 12px;
			}

			&--more {
				height: 332px;
				padding: 8px;
				overflow: scroll;
				background-color: #f2f2f2;
				border-radius: 17px;
				p {
					margin-bottom: 15px;
				}
			}

			button {
				color: #448af2;
				font-size: 14px;
				font-weight: 500;
				float: right;
				padding-left: 10px;
				line-height: 20px;
			}
		}
	}

	&__info {
		width: 100%;
		margin: 24px 0;
		display: flex;
		flex-direction: column;
		gap: 12px;

		&--language {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&--desc {
			display: flex;
			justify-content: space-between;

			span {
				line-height: normal;
			}

			button {
				display: flex;
				gap: 8px;
				align-items: center;
			}
		}

		p {
			font-size: 14px;
			color: #828282;
			font-weight: 400;
		}

		span {
			color: #333;
			font-weight: 500;
			font-size: 14px;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		padding: 24px 0 32px;
		background: rgba(242, 242, 242, 0.4);

		&--logo {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 12px;
			font-size: 14px;

			&--img {
				width: 64px;
				height: 64px;
			}
		}

		.root__btn {
			width: 180px;
			margin: 0;

			button {
				font-size: 14px;
			}
		}
	}
}

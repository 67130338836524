@import '../../assets/styles/variables';

.function {
    padding: 64px 0;
    background-color: $primary-section-bg;
    position: relative;
    background-image: url('../../assets/svg/function-bg.svg');

    .function__icon--folder {
        position: absolute;
        left: 12.82%;
        right: 82.11%;
        top: 1.6%;
        bottom: 70.74%;
        transform: rotate(-1.81deg);
    }

    .function__icon--paper {
        position: absolute;
        width: 55.22px;
        height: 70.09px;
        left: 69px;
        top: 91.61px;
    }

    .function__icon--card {
        position: absolute;
        width: 50.6px;
        height: 57.62px;
        left: 12.38px;
        top: 300px;
    }

    .function__icon--pain {
        position: absolute;
        left: 11.1%;
        right: 86.55%;
        top: 54.07%;
        bottom: 26.27%;
    }

    .function__icon--square--top {
        position: absolute;
        left: 9.96%;
        right: 86.79%;
        top: 17.14%;
        bottom: 70.1%;
    }

    .function__icon--square--bottom {
        position: absolute;
        left: 5.84%;
        right: 92.9%;
        top: 67.55%;
        bottom: 23.68%;
    }

    .function__list {
        display: flex;
        align-items: center;
        gap: 160px;
        max-width: 1196px;
        margin: 0 auto;

        .function__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:last-child {
                margin-left: 75px;
            }

            &--icon {
                height: 158px;
                width: 158px;
                border-radius: 50%;
                border: 2px solid $secondary-color;
                background-color: $white-color;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;
            }

            h5 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 52px;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 16px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
    .function {
        margin-top: 0;
        padding: 16px 0;
        background-image: url('../../assets/svg/func-bg.svg');

        .function__list {
            position: relative;
            gap: 13px;
            align-items: baseline;
            justify-content: center;

            .function__item {
                &--icon {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 8px;
                    img {
                        width: 22px;
                        height: 22px;
                    }
                }

                &:last-child {
                    margin-left: 0 !important;
                }

                h5 {
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 8px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 99px;
                }
            }
        }
    }
}

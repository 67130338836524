@use '../scss/' as *;

.navbar {
    position: fixed;
    width: 100%;
    background-color: $white-color;
    z-index: 100;

    .container {
        max-width: 1170px;
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        margin: 0 auto;
        @include respond(phone) {
            padding: 35px 24px 20px;
        }

        .logo {
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;

            img {
                @include respond(phone) {
                    display: none;
                }
            }

            h2 {
                font-size: 24px;
                line-height: 20px;
                color: $main-color;
                font-weight: $weight-bold;

                @include respond(phone) {
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }

        .navbar-right {
            display: flex;
            gap: 40px;

            @include respond(phone) {
                // display: none;
                position: absolute;
                top: 80px;
                right: -120px;
                transition: .2s all ease-in;

                &.active {
                    right: 24px;
                    z-index: 9999;
                }
            }

            ul {
                display: flex;
                align-items: center;
                gap: 40px;

                @include respond(phone) {
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 16px;
                }

                li {
                    a {
                        font-size: 20px;
                        font-weight: $weight-normal;
                        line-height: 21.5px;
                        cursor: pointer;

                        @include respond(phone) {
                            font-size: 16px;
                            line-height: 17px;
                            text-align: right;
                            color: $white-color;
                        }
                    }

                    &:first-child {
                        a {
                            color: $main-color;
                            font-size: 22px;
                            font-weight: $weight-bold;
                            line-height: 23.65px;

                            @include respond(phone) {
                                font-size: 18px;
                                line-height: 19px;
                                text-align: right;
                                color: #89A7F5;
                            }
                        }
                    }
                }
            }
            
            button {
                @include respond(phone) {
                    display: none;
                }
            }
        }

        .menu {
            display: none;

            @include respond(phone) {
                display: block;
                z-index: 201;
            }
        }
    }
}

.box-shadow {
    box-shadow: $box-shadow;
}

.bg-dark {
    @include respond(phone) {
        display: none;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }
}